
//material ui
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Paper, Typography } from "@material-ui/core";
import { ThemeProvider, createTheme } from "@material-ui/core";

import { useState } from 'react'
import Banner from '../../components/Banner/Banner'
import useFetch from '../../hooks/useFetch';
// import { useEffect } from 'react';
import React from 'react'
import ItemsLoader from './ItemsLoader';



const theme = createTheme({
    palette: {
        type: 'dark',
    },
});
//material ui.

export default function BasePage({ type }) {
    var singleType = type.substr(0, type.length - 1);

    var { data: genres } = useFetch('genres?type=' + singleType);

    var [genre, setGenre] = useState(`all`);
    var [genreIndex, setGenreIndex] = useState(`all`);


    const changeGenreIndex = (e = null) => {
        
        if (e === `all` || e.target.value === `all`) {
            setGenreIndex(`all`);
            setGenre(`all`);
        }
        else {
            console.log("selected genre ", e.target.value)
            var genre_id = isNaN(e.target.value) ? e.target.value : parseInt(e.target.value);
            setGenreIndex(genre_id);
            setGenre(genres[genre_id]);
        }

        
    }


    return (
        <div>
            <div style={{ overflow: `hidden` }}>
                <Banner type={`${type}`} />

                {genres && genre &&
                    <div>
                        <ThemeProvider theme={theme} >
                            <Paper style={{ backgroundColor: "#131313", margin:'15px'}} elevation={0}>
                                <FormControl variant="outlined" style={{ marginLeft: '20px' }} >
                                    <InputLabel id="demo-simple-select-outlined-label">Genre:</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={genreIndex}
                                        onChange={changeGenreIndex}
                                        label="Genre"
                                    >

                                        <MenuItem value={`all`} key={`all`}>{`All Genre`}</MenuItem>

                                        {genres.map((el, i) =>
                                            <MenuItem value={i} key={i}>{el.name}</MenuItem>
                                        )}

                                        {/* <MenuItem value={1}>{'Genre 1'}</MenuItem> */}

                                    </Select>
                                </FormControl>
                                {/* <Typography variant="p" component="p">
                            <br />
                            Select a Genre ⬆ </Typography> */}
                            </Paper>
                        </ThemeProvider>

                        <ItemsLoader
                        type={type}
                        genre={genre}
                        genres={genres}
                        />

                    </div>
                }
            </div>

        </div>
    )
}
