import InfiniteScroll from 'react-infinite-scroller';
import './SingleCategory.scss';
import Poster from '../../components/Poster/Poster';
import { motion } from "framer-motion";
import { staggerHalf } from "../../motionUtils";
import { BASE_API_URL } from '../../apiutils';
import axios from 'axios';
import React, { Component } from 'react'
import { CircularProgress } from '@material-ui/core';


export default class ItemsLoader extends Component {

    componentDidUpdate(prevProps) {
        if(this.props.genre.id !==  prevProps.genre.id) // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
        {
            const { type, genre } = this.props;
    
            if (genre && genre !== `all`) {
                var fetchUrl = `${BASE_API_URL}/${type}?genre=${genre.id}&page=`;
            } else {
                var fetchUrl = `${BASE_API_URL}/${type}?page=`;
            }
    
            this.setState({
                fetchUrl,
                genre,
                tracks: [],
                hasMoreItems: true,
                currentPage: 1
            });
        }
      } 


    constructor(props) {
        super(props);
        const { type, genre } = props;

        if (genre && genre !== `all`) {
            var fetchUrl = `${BASE_API_URL}/${type}?genre=${genre.id}&page=`;
        } else {
            var fetchUrl = `${BASE_API_URL}/${type}?page=`;
        }

        this.state = {
            fetchUrl,
            genre,
            tracks: [],
            hasMoreItems: true,
            currentPage: 1
        };
    }

    loadItems(page) {
        var self = this;

        var url = self.state.fetchUrl;

        if (this.state.hasMoreItems) {
            url = `${url}${self.state.currentPage}`;
        }
        
        axios.get(url)
        .then(function (resp) {
            if (resp) {
                console.log("axios",url,resp);

                   var tracks = self.state.tracks ?  self.state.tracks : [];
                        // tracks.push(resp['data']['content']);
                        resp['data']['content'].map((track) => {
                            tracks.push(track);
                        });

                    if (resp['data'].totalPages === self.state.currentPage) {
                        self.setState({
                            tracks: tracks,
                            currentPage:self.state.currentPage + 1,
                            hasMoreItems: false
                        });
                    } else {
                        self.setState({
                            tracks: tracks,
                            currentPage:self.state.currentPage + 1,
                            hasMoreItems: true
                        });
                    }
                }
            });
        }
        
        
        render() {
            
            
            const loader = <center className="MAIN_LOADER" style={{marginBottom:'20px'}}>{
                <CircularProgress color={'secondary'} key={`skeleton_Page`} />
                }</center>;
            
            var items = [];
            
            if(this.state.tracks){
                this.state.tracks.map((result, i) => {
                    console.log(result);
                    items.push(
                        <Poster
                        key={result.id}
                        // key={i}
                        item={result}
                        genre={this.props.genres?.filter((el)=> el.id === result.genre)[0]}
                        type={this.props.type.substr(0, this.props.type.length - 1)}
                        />
                        // <div></div>
                        );
                    });
                }else{
                    items.push(<div key={`SU`}></div>)
                }
                
                // window.scrollTo(0,document.body.scrollHeight/2);


                return (
                    
            <div id="main-row__under_category_flex">
            <>
                <h2 className="Category__title">{this.props.type}</h2>



               <motion.div
                    className="Category__wrp"
                    variants={staggerHalf}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                >

           <InfiniteScroll
                pageStart={500}
                loadMore={this.loadItems.bind(this)}
                hasMore={this.state.hasMoreItems}
                // style={{zIndex:999999,position:'absolute',top:'100%'}}
                 loader={loader}
                >

                    {items}

            </InfiniteScroll>

          



            </motion.div>
                    </>
            </div>
        );



    }
}
