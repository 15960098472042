import React from 'react'
import { motion } from "framer-motion";
import { staggerOne, bannerFadeInLoadSectionVariants, bannerFadeInVariants, bannerFadeInUpVariants,defaultPageFadeInVariants } from "../../motionUtils";
import Credits from "../../components/Credits/Credits";
import useFetch from '../../hooks/useFetch';

import { useParams } from 'react-router-dom';
import SkeletonBasicPage from '../../components/SkeletonBasicPage/SkeletonBasicPage';
import { useEffect } from 'react';
import '../../components/Banner/banner.scss';
import useViewport from '../../hooks/useViewport';
import { FaPlay } from "react-icons/fa";
import { Link } from 'react-router-dom';

import './VideoBackground.scss';

export default function ChannelDetails() {
    var { id } = useParams();
    var {width,height}  = useViewport();

    var { data, error, loading } = useFetch('channel/' + id);

    
    const  handleHoverMain = e => {
    //     if(data?.trailer){
    //  e.target.style.background = `none`;
    //  e.target.style.backgroundImage = `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) )`;
    //  if(document.getElementById('yt__Trailer').src !== `https://www.youtube.com/embed/${data?.trailer}?autoplay=1&mute=1&controls=0&showinfo=0&rel=0&loop=1&playlist=${data?.trailer}`){
    //  document.getElementById('yt__Trailer').src = `https://www.youtube.com/embed/${data?.trailer}?autoplay=1&mute=1&controls=0&showinfo=0&rel=0&loop=1&playlist=${data?.trailer}`;

    //  }
    // }
    }

    useEffect(() => {
        setTimeout(()=>{
            document.getElementById('Main_trailer_loader').focus();
        },[1000])

        return () => { console.log('removed channel Details') }
    }, []);


    // var [currentSource, setCurrentSource] = useState(null);


    // handleChangeSrc = (e) => {
    //     changeSrc(e.target.value);
    // }



    return (
        <div>
            {!loading && !error && <motion.div
                className="Homepage"
                variants={defaultPageFadeInVariants}
                initial="initial"
                animate="animate"
                exit="exit"
            >




                <br />


                {data?.trailer &&





(

<div className="video-background" >
    <div className="video-foreground">
      <iframe id="yt__Trailer" src={``} frameBorder="0" allowFullScreen></iframe>
    </div>
  </div>

)

}

{/* <JWPlayer
    height={width >= 780 ? '900px' : '400px'}
    url={data?.trailer}
    extension={'youtube'}
    subtitle={data?.subtitle}
    poster={data?.poster}
    style={{position:'relative',zIndex: 9,
    width: '100%',
    top: '-100px'}}
/> */}


<motion.div className="vid-info"  
whileHover={{scale:1.2}}
initial={{opacity:1}}
animate={{opacity:0}}    
transition={{type:"tween",duration:4}}
>
    <img src={`${data?.thumb}`}  style={{height:'40%',width:'40%'}}/>
    </motion.div>

                <motion.section
				variants={bannerFadeInLoadSectionVariants}
				initial='initial'
				animate='animate'
				exit='exit'
				className="Banner__loadsection"
                id="vidtop-content"
			>
                <motion.div
                    variants={bannerFadeInVariants}
                    initial='initial'
                    animate='animate'
                    exit='exit'
                    className="Banner"
                    // whileHover={{ backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) )`,background:`none` }}
                    // onHoverStart={handleHoverMain}
                    // onHoverEnd={handleHoverMain}
                    // onFocus={handleHoverMain}
                    id='Main_trailer_loader'
                    // style={}
                    style={{ backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ),url('${data?.poster}')` }}

                >
                    <motion.div
                        className="Banner__content"
                        style={{zIndex:10}}
                        variants={staggerOne}
                        initial='initial'
                        animate='animate'
                        exit='exit'
                    >


                        <br />
                        <motion.h1 variants={bannerFadeInUpVariants} className="Banner__content--title"> {data?.name}</motion.h1>

                        <motion.div variants={bannerFadeInUpVariants} className="Banner__buttons">

<Link
    className="Banner__button"
    to={`/play/channel/${id}`}
>
    <FaPlay />
    <span>Play</span>
</Link>
</motion.div>
                       

                        <motion.p variants={bannerFadeInUpVariants} className="Banner__content--description">{data?.description}</motion.p>
                    </motion.div>

            

                    <div className="Banner__panel" />
                    <div className="Banner__bottom-shadow" />
                </motion.div>


            </motion.section>
            </motion.div>
            
            
            }

            {loading && <SkeletonBasicPage />}

            <Credits />
        </div>
    )
}
