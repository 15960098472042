import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import './polyFill';

import { BrowserRouter } from "react-router-dom";
import { registerServiceWorker } from './firebase/serviceWorker';


ReactDOM.render(
  // <React.StrictMode>
    <BrowserRouter>
    <App />
    </BrowserRouter>,
  // </React.StrictMode>,
  document.getElementById('root')
);

registerServiceWorker()


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
