import "./homepage.scss"
import Banner from "../../components/Banner/Banner"
import Row from "../../components/Row/Row"
import Credits from "../../components/Credits/Credits";
import { useState } from "react";

// import { useRetrieveData } from "../../hooks/useRetrieveData";

import { motion } from "framer-motion";
import { defaultPageFadeInVariants } from "../../motionUtils";

const Homepage = () => {
    // const rows = useRetrieveData('movies');
const [movies, setmovies] = useState(null);

    return (
        <motion.div
            className="Homepage"
            variants={defaultPageFadeInVariants}
            initial="initial"
            animate="animate"
            exit="exit"
        >
            <Banner type={'movies'} />
            <br />
            <Row selector='movie' title='Latest Movies' isLarge={false} />
            <Row selector='serie' title='Latest Series' isLarge={false}/>
            <Row selector='channel' title='Latest Channels' isLarge={false}/> 
            <Credits />
        </motion.div>
    )
}

export default Homepage
