// import axios from "axios";

 export const BASE_API_URL = 'https://newflixy-y84m.onrender.com/api/web';
//export const BASE_API_URL = 'https'https://newflixybackend-production.up.railway.app/api/web';

// THIS IS OLD API 
// UNABLE TO USE IT AS REACT DOSENT PERMITS IT (ASYNC FUNCTIONS) IN FUNCTUINAL COMPONENTS 


// Multi Data

// export const getMovies = async (page = null) => {
//     var data = page ? await axios.get(`${BASE_API_URL}/movies?page=${page}`) : await axios.get(`${BASE_API_URL}/movies`);
//     return data["data"];
// }

// export const getSeries = async (page = null) => {
//     var data = page ? await axios.get(`${BASE_API_URL}/series?page=${page}`) : await axios.get(`${BASE_API_URL}/series`);
//     return data["data"];
// }

// export const getChannels = async (page = null) => {
//     var data = page ? await axios.get(`${BASE_API_URL}/channels?page=${page}`) : await axios.get(`${BASE_API_URL}/channels`);
//     return data["data"];
// }

// // Multi Data






// //Sources Handler
// export const getChannelsSources = async (id = null) => {
//     var data = id ? await axios.get(`${BASE_API_URL}/channel/sources?id=${id}`) : ["data"=null];
//     return data["data"];
// }


// export const getMoviesSources = async (id = null) => {
//     var data = id ? await axios.get(`${BASE_API_URL}/movie/sources?id=${id}`) : ["data"=null];
//     return data["data"];
// }
// //Sources Handler




// //Series Handler
// export const getSeasons = async (id = null) => {
//     var data = id ? await axios.get(`${BASE_API_URL}/serie/seasons?id=${id}`) : ["data"=null];
//     return data["data"];
// }


// export const getEpisodes = async (seasonid = null) => {
//     var data = seasonid ? await axios.get(`${BASE_API_URL}/season/episodes?id=${seasonid}`) : ["data"=null];
//     return data["data"];
// }


// export const getEpisodeSources = async (episodeid = null) => {
//     var data = episodeid ? await axios.get(`${BASE_API_URL}/episode/sources?id=${episodeid}`) : ["data"=null];
//     return data["data"];
// }
// //Series Handler







// // Single Data
// export const getSingleMovie = async (id = null,latest=false) => {
//     if(latest){
//         var data = await axios.get(`${BASE_API_URL}/movie/latest`);
//         return data["data"];
//     }

//     var data = id ? await axios.get(`${BASE_API_URL}/movie?id=${id}`) : ["data"=null];
//     return data["data"];
// }

// export const getSingleSerie = async (id = null,latest=false) => {
//     if(latest){
//         var data = await axios.get(`${BASE_API_URL}/serie/latest`);
//         return data["data"];
//     }

//     var data = id ? await axios.get(`${BASE_API_URL}/serie?id=${id}`) : ["data"=null];
//     return data["data"];
// }

// export const getSingleChannel = async (id = null,latest=false) => {
//     if(latest){
//         var data = await axios.get(`${BASE_API_URL}/channel/latest`);
//         return data["data"];
//     }

//     var data = id ? await axios.get(`${BASE_API_URL}/channel?id=${id}`) : ["data"=null];
//     return data["data"];
// }
//Single Data 
