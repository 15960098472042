import React from 'react'
import { motion } from "framer-motion";
import { staggerOne, bannerFadeInLoadSectionVariants, bannerFadeInVariants, bannerFadeInUpVariants, defaultPageFadeInVariants } from "../../motionUtils";
import Credits from "../../components/Credits/Credits";
import useFetchtmdb from '../../hooks/useFetchtmdb';

import { useParams } from 'react-router-dom';
import SkeletonBasicPage from '../../components/SkeletonBasicPage/SkeletonBasicPage';
import { useEffect } from 'react';
import '../../components/Banner/banner.scss';
import useViewport from '../../hooks/useViewport';
import { FaPlay } from "react-icons/fa";
import { Link } from 'react-router-dom';


import { BASE_IMG_URL,FALLBACK_IMG_URL } from '../../requests';
export default function CastDetails() {
    var { id } = useParams();
    var { width, height } = useViewport();

    var { data, error, loading } = useFetchtmdb('person/' + id);


    const handleHoverMain = e => {
        //     if(data?.trailer){
        //  e.target.style.background = `none`;
        //  e.target.style.backgroundImage = `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) )`;
        //  if(document.getElementById('yt__Trailer').src !== `https://www.youtube.com/embed/${data?.trailer}?autoplay=1&mute=1&controls=0&showinfo=0&rel=0&loop=1&playlist=${data?.trailer}`){
        //  document.getElementById('yt__Trailer').src = `https://www.youtube.com/embed/${data?.trailer}?autoplay=1&mute=1&controls=0&showinfo=0&rel=0&loop=1&playlist=${data?.trailer}`;

        //  }
        // }
    }

    useEffect(() => {
        setTimeout(() => {
            document.getElementById('Main_trailer_loader').focus();
        }, [1000])

        return () => { console.log('removed Cast Details') }
    }, []);


    // var [currentSource, setCurrentSource] = useState(null);


    // handleChangeSrc = (e) => {
    //     changeSrc(e.target.value);
    // }



    return (
        <div>
            {!loading && !error && <motion.div
                className="Homepage"
                variants={defaultPageFadeInVariants}
                initial="initial"
                animate="animate"
                exit="exit"
            >




                <br />


                {data?.trailer &&





                    (

                        <div className="video-background" >
                            <div className="video-foreground">
                                <iframe id="yt__Trailer" src={``} frameBorder="0" allowFullScreen></iframe>
                            </div>
                        </div>

                    )

                }

                {/* <JWPlayer
    height={width >= 780 ? '900px' : '400px'}
    url={data?.trailer}
    extension={'youtube'}
    subtitle={data?.subtitle}
    poster={data?.poster}
    style={{position:'relative',zIndex: 9,
    width: '100%',
    top: '-100px'}}
/> */}



             { width > 1000 && 
                <motion.div className="vid-info"
                    initial={{ opacity: 1 }}
                    animate={{ opacity: 0.9 }}
                    transition={{ type: "tween", duration: 4 }}
                >
                    		{data?.profile_path ?
					<img src={`${BASE_IMG_URL}${data?.profile_path}`} alt={data?.name}  style={{ height: '48%', width: '14%',position:'fixed',top:'40px',right:'40px' }}  />
        : 					<img src={`${FALLBACK_IMG_URL}`} alt={data?.name}   style={{ height: '48%', width: '14%' ,position:'fixed',top:'40px',right:'40px'}} />
    }

                </motion.div>
}



                <motion.section
                    variants={bannerFadeInLoadSectionVariants}
                    initial='initial'
                    animate='animate'
                    exit='exit'
                    className="Banner__loadsection"
                    id="vidtop-content"
                >
                    <motion.div
                        variants={bannerFadeInVariants}
                        initial='initial'
                        animate='animate'
                        exit='exit'
                        className="Banner"
                        // whileHover={{ backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) )`,background:`none` }}
                        // onHoverStart={handleHoverMain}
                        // onHoverEnd={handleHoverMain}
                        // onFocus={handleHoverMain}
                        id='Main_trailer_loader'
                        // style={}
                        style={{ backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ),url('${BASE_IMG_URL}${data?.profile_path}')` }}

                    >
                        <motion.div
                            className="Banner__content"
                            style={{ zIndex: 10 }}
                            variants={staggerOne}
                            initial='initial'
                            animate='animate'
                            exit='exit'
                        >


                            <br />
                            <motion.h1 variants={bannerFadeInUpVariants} className="Banner__content--title"> {data?.name}</motion.h1>
                            {data?.popularity &&
                                <motion.h1 variants={bannerFadeInUpVariants} className="Banner__content--description">Popularity: {data?.popularity}</motion.h1>

                            }

                            {data?.birthday &&
                                <motion.h1 variants={bannerFadeInUpVariants} className="Banner__content--description">Birthday: {data?.birthday}</motion.h1>

                            }

                            {data?.deathday &&
                                <motion.h1 variants={bannerFadeInUpVariants} className="Banner__content--description">Death Date: {data?.deathday}</motion.h1>

                            }

                            {data?.place_of_birth &&
                                <motion.h1 variants={bannerFadeInUpVariants} className="Banner__content--description">Place of Birth: {data?.place_of_birth}</motion.h1>

                            }


                            <div className="Row__poster-info--genres">
                                {(
                                    <span key={`Genre--id_${data?.id}`} className="genre-description">{data?.known_for_department} · {data?.gender === 1 && 'Female'} {data?.gender === 2 && 'Male'} <br /> 	 <br /> 		<div className="Row__poster-info--genres">

                                        {data?.also_known_as && data?.also_known_as.map((genre,id) => (
                                            <span key={`Genre--id_${genre}`} className="genre-description">{id===0 && 'Also knows As :  '}{genre} · </span> 
                                        ))}

                                    </div>

                                    </span>
                                )}
                            </div>


                            <motion.p variants={bannerFadeInUpVariants} className="Banner__content--description">{data?.biography.substr(0,200)}</motion.p>
                        </motion.div>



                        <div className="Banner__panel" />
                        <div className="Banner__bottom-shadow" />
                    </motion.div>


                </motion.section>
            </motion.div>


            }

            {loading && <SkeletonBasicPage />}

            <Credits />
        </div>
    )
}
