import "./rowPoster.scss";
import { BASE_IMG_URL, FALLBACK_IMG_URL } from "../../requests";
import { FaPlus, FaMinus, FaPlay, FaChevronDown } from "react-icons/fa";
import { Link, Redirect } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import { useHistory } from "react-router";
import { isMobile } from "react-device-detect";

const RowPoster = result => {
	const {  name, genre:genre_id, poster:poster_path, thumb:backdrop_path ,id:dataID,type:playType,isLarge,genre} = result;

	let fallbackTitle = name;


	// var {data:genresConverted,loading} = useFetch(`genre/${genre_id}`);
	
   var history = useHistory();




	const handleModalOpening = () => {
		// Redirect();
		history.push(`/play/${playType}/${dataID}`);
		// dispatch(showModalDetail({ ...item, fallbackTitle, genresConverted, isFavourite }));
	}
	const handlePlayAction = (e) => {
		console.log(e.target);
		e.stopPropagation();
	};

	return (
		<Link
			className={`Row__poster ${isLarge ? "Row__poster--big" : ""}`}
			// onClick={handleModalOpening}
			// to={`/play/${playType}/${dataID}`}
			to={isMobile ? `/play/${playType}/${dataID}`: `/${playType}/${dataID}`}
			style={{color:'white'}}
		>
			{isLarge ? (
				poster_path ? (
					<img src={`${poster_path}`} alt={fallbackTitle} />
				) : ""
			) : backdrop_path ? (
				<img src={`${backdrop_path}`} alt={fallbackTitle} />
			) : (
				<>
					<img src={FALLBACK_IMG_URL} alt={fallbackTitle} />
					<div className="Row__poster__fallback">
						<span>{fallbackTitle}</span>
					</div>
				</>
			)}
			<div className="Row__poster-info">
				<div className="Row__poster-info--iconswrp">
					<Link
						className="Row__poster-info--icon icon--play"
						onClick={() => handlePlayAction}
						to={`/play/${playType}/${dataID}`}
						>
						<FaPlay />
					</Link>
				
							<Link className='Row__poster-info--icon icon--favourite' to={`/${playType}/${dataID}`}>
								<FaPlus />
							</Link>
						
					{/* <button className='Row__poster-info--icon icon--toggleModal'>
						<FaChevronDown onClick={handleModalOpening}/>
					</button> */}

				</div>
				<div className="Row__poster-info--title">
					<h3>{fallbackTitle}</h3>
				</div>
				<div className="Row__poster-info--genres">
					{genre && (
						<span key={`Genre--id_${genre?.id}`} className="genre-title">{genre?.name}</span>
					)}
				</div>
			</div>
		</Link>
	);
};

export default RowPoster;