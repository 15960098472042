import React from 'react'
import { motion } from "framer-motion";
import { staggerOne, bannerFadeInLoadSectionVariants, bannerFadeInVariants, bannerFadeInUpVariants,defaultPageFadeInVariants } from "../../motionUtils";
import Credits from "../../components/Credits/Credits";
import { useState } from "react";
import useFetch from '../../hooks/useFetch';
import { FaChromecast, FaOpenid, FaPlay } from "react-icons/fa";
import { FaDownload } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import SkeletonBasicPage from '../../components/SkeletonBasicPage/SkeletonBasicPage';
import JWPlayer from '../../components/Player/JWPlayer';
import { useEffect } from 'react';
import '../../components/Banner/banner.scss';
import useViewport from '../../hooks/useViewport';
import RowSeasons from '../../components/Row/RowSeasons';
import { isAndroid } from 'react-device-detect';


export default function SeriePlayer() {
    var { id } = useParams();
    var {width,height}  = useViewport();

    var { data, error, loading } = useFetch('serie/alldata/' + id);


    useEffect(() => {

        // set


        if(data?.seasons){
        setSeasons(data?.seasons);
        if(data?.seasons[0]){
            setCurrentSeason(data?.seasons[0]);
        }
        if(data?.seasons[0]?.episodes[0]){
            changeEpisode(data?.seasons[0]?.episodes[0]);
            setEpisodes(data?.seasons[0]?.episodes)
        setSources(
            data?.seasons[0]?.episodes !== [] ? data?.seasons[0]?.episodes[0]?.sources : null
        );
        }
        }

        if(sources){
           changeSrc(0);
        }
        document.addEventListener("DOMContentLoaded", function(){
            window.scrollTo(0,0);
         });
         
        return () => { console.log('removed Movie player') }
    }, [data,seasons]);

    var [currentSource, setCurrentSource] = useState(null);
    var [currentSeason, setCurrentSeason] = useState(null);
    var [currentEpisode, setCurrentEpisode] = useState(null);

    var [seasons,setSeasons] = useState(null);
    var [sources,setSources] = useState(null);
    var [episodes,setEpisodes] = useState(null);


    // handleChangeSrc = (e) => {
    //     changeSrc(e.target.value);
    // }

    const changeSrc = (id) => {
        setCurrentSource(sources.filter(ele => ele.type === 'both' || ele.type === 'play')[id]);
    }

    const changeTheSeasonByid = (element) =>{
        //changed season from the fucK uI
        console.log('fU*K');
        // console.log(element.target.value,'season changed');
        setCurrentSeason(seasons ? seasons[element.target.value] : null);
        // changeEpisodes()
    }


    const changeEpisode = (episode) =>{
       setCurrentEpisode(episode);

       if(episode?.sources){
           setSources(episode?.sources);
           setCurrentSource(episode?.sources[0] ? episode?.sources[0] : null)
       }
    }

    // const changeEpisodes =  (seasonid) => {
    //     setEpisodes(seasons[seasonid]["episodes"]);
    // }


    return (
        <div>
            {!loading && !error && <motion.div
                className="Homepage"
                variants={defaultPageFadeInVariants}
                initial="initial"
                animate="animate"
                exit="exit"
            >

                {currentSource &&

                    <JWPlayer
                        height={width >= 780 ? '700px' : '500px'}
                        url={currentSource?.url}
                        extension={currentSource?.ext}
                        subtitle={currentEpisode?.subtitle}
                        poster={data?.poster}
                        drmkey={currentSource?.drmkey}
                    />
                }
                {!currentSource && <>
                    <br />
                    <br />
                    <br />
                    <br />
                </>}


{seasons && <RowSeasons
                parentData={data}
                currentEpisode={currentEpisode}
                currentSeason={currentSeason}
                episodes={episodes}
                seasons={seasons}
                setEpisode={changeEpisode}
                setSeason={changeTheSeasonByid}
                />}


                <br />


                <motion.section
				variants={bannerFadeInLoadSectionVariants}
				initial='initial'
				animate='animate'
				exit='exit'
				className="Banner__loadsection"
			>
                <motion.div
                    variants={bannerFadeInVariants}
                    initial='initial'
                    animate='animate'
                    exit='exit'
                    className="Banner CoverBanner"
                    style={{ backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ),url('${data?.poster}')` }}
                >
                    <motion.div
                        className="Banner__content"
                        variants={staggerOne}
                        initial='initial'
                        animate='animate'
                        exit='exit'
                    >

            { sources && sources.filter(ele => ele.type === 'both' || ele.type === 'play').length > 0 && 
             <motion.h6 variants={bannerFadeInUpVariants} className="Banner__content--description">Playable Qualites:</motion.h6>
            }


                        <motion.div variants={bannerFadeInUpVariants} className="Banner__buttons CoverBanner__buttons">

                            {sources && sources.filter(ele => ele.type === 'both' || ele.type === 'play').map((element,i) => 
                                <button
                                style={(sources.filter(ele => ele.type === 'both' || ele.type === 'play').indexOf(currentSource) === i) ? {backgroundColor:'#e50914'} : {backgroundColor:'black'}}
                                    className="Banner__button CoverBanner__button"
                                    onClick={()=>{changeSrc(i)}}
                                    key={i}
                                >
                                    <FaPlay />
                                    <span>Play {element?.quality} {element?.name} {element?.ext} {(sources.filter(ele => ele.type === 'both' || ele.type === 'play').indexOf(currentSource) === i) && (<div style={{fontSize:'9px'}}>Now Playing</div>)} </span>

                                </button>
                            )}


                      
                        </motion.div>


                        { sources && sources.filter(ele => ele.type === 'both' || ele.type === 'download').length > 0 && 

                        <motion.h6 variants={bannerFadeInUpVariants} className="Banner__content--description">Downloadable Qualites:</motion.h6>

                        }

<motion.div variants={bannerFadeInUpVariants} className="Banner__buttons CoverBanner__buttons">

    {sources && sources.filter(ele => ele.type === 'both' || ele.type === 'download').map((element,i) => 
        <a
        style={{backgroundColor:'black'}}
            className="Banner__button CoverBanner__button"
            target="_blank"
            href={element?.url}
            key={i}
        >
            <FaDownload />
            <span>Download {element?.quality} {element?.name} {element?.ext} </span>

        </a>
    )}



</motion.div>










{sources && sources.filter(ele => ele.type === 'both' || ele.type === 'play').length > 0 &&
                                <motion.h6 variants={bannerFadeInUpVariants} className="Banner__content--description">Castable Qualites:</motion.h6>
                            }


                            <motion.div variants={bannerFadeInUpVariants} className="Banner__buttons CoverBanner__buttons">

                                {sources && sources.filter(ele => ele.type === 'both' || ele.type === 'play').map((element, i) =>
                                    <a
                                        style={{ backgroundColor: 'black' }}
                                        className="Banner__button CoverBanner__button"
                                        target="_blank"
                                        href={`https://chromecast.link/#title=${encodeURIComponent(`${currentEpisode?.name} of  ${currentSeason?.name} ${data?.name}`)}&poster=${encodeURIComponent(data?.poster)}&content=${encodeURIComponent(element?.url)}&subtitle=${encodeURIComponent(currentEpisode?.subtitle)}`}
                                        key={i}
                                    >
                                        <FaChromecast />
                                        <span>Cast {element?.quality} {element?.name} {element?.ext} </span>

                                    </a>
                                )}



                            </motion.div>







                            {isAndroid && (<>
                                {sources && sources.filter(ele => ele.type === 'both' || ele.type === 'play').length > 0 &&
                                    <motion.h6 variants={bannerFadeInUpVariants} className="Banner__content--description">Open In Other Player(need to be installed):</motion.h6>
                                }


                                <motion.div variants={bannerFadeInUpVariants} className="Banner__buttons CoverBanner__buttons" >

                                    {sources && sources.filter(ele => ele.type === 'both' || ele.type === 'play').map((element, i) =>
                                    <div key={i} >
                                        <a
                                        style={{ backgroundColor: 'black' }}
                                        className="Banner__button CoverBanner__button"
                                        target="_blank"
                                        href={`vlc://${element?.url}`}
                                    >
                                        <FaOpenid />
                                        <span>Open in VLC {element?.quality} {element?.name} {element?.ext} </span>

                                    </a>

                                    <a
                                            style={{ backgroundColor: 'black' }}
                                            className="Banner__button CoverBanner__button"
                                            target="_blank"
                                            href={`intent:${element?.url}#Intent;package=com.mxtech.videoplayer.ad;S.title=${encodeURIComponent(`${currentEpisode?.name} of  ${currentSeason?.name} ${data?.name}`)};end`}
                                            key={i}
                                        >
                                            <FaOpenid />
                                            <span>Open in Mx Player {element?.quality} {element?.name} {element?.ext} </span>

                                        </a>
                                    </div>

                                    
                                    )}



                                </motion.div>
                            </>
                            )

                            }



                        <br />
                        <motion.h1 variants={bannerFadeInUpVariants} className="Banner__content--description">Current Episode : {currentEpisode?.name} of  {currentSeason?.name}</motion.h1>
                        <motion.h1 variants={bannerFadeInUpVariants} className="Banner__content--description">Votes : {data?.vote}</motion.h1>
                        <motion.h1 variants={bannerFadeInUpVariants} className="Banner__content--description">Popularity : {data?.popularity}</motion.h1>

                        <motion.p variants={bannerFadeInUpVariants} className="Banner__content--description">{data?.description.substr(0,200)}...</motion.p>
                    </motion.div>
                    <div className="Banner__panel" />
                    <div className="Banner__bottom-shadow" />
                </motion.div>


            </motion.section>




            </motion.div>
            
            
            }

            {loading && <SkeletonBasicPage />}

            <Credits />
        </div>
    )
}
