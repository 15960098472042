import React from 'react';
import { useLocation, useParams } from 'react-router';
import useFetch from '../../hooks/useFetch';
import SkeletonBasicPage from '../../components/SkeletonBasicPage/SkeletonBasicPage';
import { motion } from "framer-motion";
import { staggerHalf } from "../../motionUtils";
import Poster from '../../components/Poster/Poster';
import { useEffect } from 'react';
import '../Category/category.scss';
import useViewport from '../../hooks/useViewport';

export default function SearchPage() {

    var indexOfindex = ["movies","series","channels"];
    // var query = URLSearchParams(useLocation().search);
    var {width} = useViewport();
    // var search_term = query.get("q");
    if(width < 780){
        var padtop = '23%';
    }else{
        var padtop = '5%';
    }

    var  { query: search_term} = useParams();


    var { data, loading, error } = useFetch('search/' + encodeURIComponent(search_term));

    var { data: genres } = useFetch('genres');

    useEffect(() => {
        console.log(data,"is data",data?.length)
        return () => {
            
        }
    }, [data])

    return (
        <div style={{padding:'5%',paddingTop:padtop }}>

            {data && !data.error && data.length > 0 && data.map((e, i) => {
             
             return(

                <div id="main-row__under_category_flex" key={i}>
                    <h2 className="Category__title">{indexOfindex[i]}</h2>

                    <motion.div
                        className="Category__wrp"
                        variants={staggerHalf}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                    >

                        {e && e.length > 0
                            && e.map(result => (
                                <Poster
                                    key={result.id}
                                    item={result}
                                    genre={genres?.filter((el) => el.id === result.genre)[0]}
                                    type={indexOfindex[i].substr(0, indexOfindex[i].length - 1)}
                                />
                            ))
                        }
                        
                        
                        {e.length === 0 && <div className='Category__subtitle'>No {indexOfindex[i].substr(0, indexOfindex[i].length - 1)} Found</div>}


                    </motion.div>
                    <br />
                </div>
             );
            })}

            {loading && <SkeletonBasicPage />}
            {error && <div className='Category__subtitle'>Oops, an error occurred.</div>}
            {data && data.error && <div className='Category__subtitle'>{data.error}</div>}

        </div>
    )
}
