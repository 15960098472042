import NoSleep from 'nosleep.js';
import React from 'react'
import { JWPLAYER_API_KEY, LOGO_URL } from '../../requests';
import Plyr from 'plyr';
import { useEffect } from 'react';
import quick_and_dirty_vtt_or_srt_parser from './Helper';
import axios from 'axios';



const Hls = window.Hls;
const dashjs = window.dashjs;
var noSleep = new NoSleep();






export default function JWPlayer({ url, extension, subtitle, poster, drmkey, height, style }) {

    useEffect(() => {

        console.log(`
        %c 
        ███████╗██╗     ██╗██╗  ██╗██╗   ██╗    ██████╗ ██╗      █████╗ ██╗   ██╗███████╗██████╗   
        ██╔════╝██║     ██║╚██╗██╔╝╚██╗ ██╔╝    ██╔══██╗██║     ██╔══██╗╚██╗ ██╔╝██╔════╝██╔══██╗
        █████╗  ██║     ██║ ╚███╔╝  ╚████╔╝     ██████╔╝██║     ███████║ ╚████╔╝ █████╗  ██████╔╝
        ██╔══╝  ██║     ██║ ██╔██╗   ╚██╔╝      ██╔═══╝ ██║     ██╔══██║  ╚██╔╝  ██╔══╝  ██╔══██╗
        ██║     ███████╗██║██╔╝ ██╗   ██║       ██║     ███████╗██║  ██║   ██║   ███████╗██║  ██║
        ╚═╝     ╚══════╝╚═╝╚═╝  ╚═╝   ╚═╝       ╚═╝     ╚══════╝╚═╝  ╚═╝   ╚═╝   ╚══════╝╚═╝  ╚═╝
        `, 'color: blue');

        console.log(`
        %c 
        WE DONT OWN ANY OF THE CONTENT 
        ALl Content we have Taken From the internet
        if you have Any Complains (DCMA) Report to anandrambkn@gmail.com <> 

        `, 'color: red');

        try {
            setTimeout(() => {


                if (height && (extension !== 'mp4' && extension !== 'mpd' && extension !== 'm3u8' && extension !== 'youtube' && extension !== 'embed')) {
                    // document.querySelector('.plyr').style.height = height;
                    window.addNewStyle(`
                    .plyr{
                        height:${height};
                    }
                    
                    `);
                } else if (height && (extension === 'mp4' || extension === 'mpd' || extension === 'm3u8')) {
                    if (document.body.contains(document.querySelector('.jwplayer'))) {
                        // document.querySelector('.jw-wrapper.jw-reset').style.height= height;

                        // document.querySelector('.jwplayer').style.setProperty('height', height, 'important');
                    }
                    window.addNewStyle(`
                        #main__player__jw{
                            height:${height}!important;
                        }
                        
                        `);


                }
                window.scrollTo(0, 0);


            }, 200);

        } catch (e) {
            console.log(e);
        }


        // console.log('player cleared');
        noSleep.enable(); // keep the screen on!

        window.jwplayer.key = JWPLAYER_API_KEY;
        startPlay(url, extension, subtitle, poster, drmkey);

        return () => {
            console.log("useEffect RAN");

            window.jwplayer.key = '';

            if (window.playera) { window.playera.reset() && console.log("SuccessFully Destroyed Dash.js Player"); }


            if (window.hls) { window.hls.destroy() && console.log("SuccessFully Destroyed HLS.js Player"); }


            // if(document.body.contains(document.querySelector('video'))){
            //     document.querySelector('video').pause();
            //     document.querySelector('video').removeAttribute('src');
            //     document.querySelector('video').load();
            // } 


            try {
                window.jwplayer('main__player__jw').remove();
            } catch (e) { console.log(e) };



            try {
                if (document.body.contains(document.querySelector('video'))) {
                    // document.querySelector('.video__tag').remove();
                    var videoElement = document.querySelector('video');
                    videoElement.pause();

                    if (videoElement.hasChildNodes()) {
                        videoElement.removeChild(videoElement.childNodes[0]);
                    }

                    videoElement.removeAttribute('src'); // empty source
                    videoElement.load();
                    // videoElement.remove();
                }

            } catch (e) { console.log(e) };

            try {
                window.removeNewStyle();
            } catch (e) { console.log(e) };



            // window.jwplayer.key = '';

            // if(window.playera)
            // { window.playera.reset() && console.log("SuccessFully Destroyed Dash.js Player");}


            // if(window.hls) 
            // {window.hls.destroy() && console.log("SuccessFully Destroyed HLS.js Player");}




            // try{
            // window.jwplayer('main__player__jw').remove();
            // }catch(e){ console.log(e) };           

            noSleep.disable(); // keep the screen off!

            console.log('player cleared');
        };
    }, [url]);




    function startPlay(url, extension, subtitle, poster, drmkey = null) {

        try {
            if (document.body.contains(document.querySelector('video'))) {
                // document.querySelector('.video__tag').remove();
                var videoElement = document.querySelector('video');
                videoElement.pause();

                if (videoElement.hasChildNodes()) {
                    videoElement.removeChild(videoElement.childNodes[0]);
                }

                videoElement.removeAttribute('src'); // empty source
                videoElement.load();
                // videoElement.remove();
            }

        } catch (e) { console.log(e) };

        if (extension === 'mp4' || extension === 'mpd' || extension === 'm3u8') {

            var subtitle__object = subtitle ? { "file": subtitle, "label": "English", "kind": "captions", "default": "true" } : {};
            var fallBack__image = poster ? poster : LOGO_URL;

            var jw__player_object = drmkey ? {
                file: url,
                drm: {
                    widevine: {
                        url: drmkey,
                    },
                },
                tracks: [subtitle__object],
            } :
                {
                    file: url,
                    tracks: [subtitle__object],
                };


            jw__player_object = {
                ...jw__player_object,
                abouttext: 'FLIXY WEB',
                aboutlink: 'http://flixy.ga',
                aspectratio: "16:9",
                autostart: true,
                displaydescription: true,
                description: "You're Watching",
                skin: {
                    name: "netflix"
                },
                image: fallBack__image,
                cast: {
                    appid: "00000000"
                },
                controls: true,
                displaydescription: true,
                displaytitle: true,
                height: 360,
                logo: {
                    file: "https://cdn.jsdelivr.net/gh/dgbkn/flixyfroontend@main/logo.png",
                    hide: true,
                    link: "https://google.com",
                    margin: "10",
                    position: "control-bar"
                },
                mute: false,
                ph: 1,
                pid: "KB5zFt7A",
                playbackRateControls: true,
                preload: "metadata",
                repeat: false,
                sharing: {
                    heading: "Share Link",
                    sites: [
                        "facebook",
                        "twitter",
                        "email"
                    ]
                },
                stretching: "uniform",
                width: "100%"
            };




            const playerInstance = window.jwplayer("main__player__jw").setup(jw__player_object);


            playerInstance.on("ready", function () {
                // Move the timeslider in-line with other controls
                const playerContainer = playerInstance.getContainer();
                const buttonContainer = playerContainer.querySelector(".jw-button-container");
                // const spacer = buttonContainer.querySelector(".jw-spacer");
                // const timeSlider = playerContainer.querySelector(".jw-slider-time");
                // buttonContainer.replaceChild(timeSlider, spacer);



                const player = playerInstance;

                // display icon
                const rewindContainer = playerContainer.querySelector('.jw-display-icon-rewind');
                const forwardContainer = rewindContainer.cloneNode(true);
                const forwardDisplayButton = forwardContainer.querySelector('.jw-icon-rewind');
                forwardDisplayButton.style.transform = "scaleX(-1)";
                forwardDisplayButton.ariaLabel = "Forward 10 Seconds"
                const nextContainer = playerContainer.querySelector('.jw-display-icon-next');
                nextContainer.parentNode.insertBefore(forwardContainer, nextContainer);


                // control bar icon
                playerContainer.querySelector('.jw-display-icon-next').style.display = 'none'; // hide next button
                const rewindControlBarButton = buttonContainer.querySelector(".jw-icon-rewind");
                const forwardControlBarButton = rewindControlBarButton.cloneNode(true);
                forwardControlBarButton.style.transform = "scaleX(-1)";
                forwardControlBarButton.ariaLabel = "Forward 10 Seconds";
                rewindControlBarButton.parentNode.insertBefore(forwardControlBarButton, rewindControlBarButton.nextElementSibling);

                // add onclick handlers
                [forwardDisplayButton, forwardControlBarButton].forEach(button => {
                    button.onclick = () => {
                        player.seek((player.getPosition() + 10));
                    }
                })
            });
            
            

        } else {
            var video__refrence = '#main_video';
            const video_tag = document.getElementById(video__refrence);

            if (extension === 'superm3u8') {
                // var defaultOptions = {};

                // if (Hls.isSupported()) {

                //     var hls = new Hls();
                //     hls.loadSource(url);

                //     hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {

                //         // Transform available levels into an array of integers (height values).
                //         var availableQualities = hls.levels.map((l) => l.height)

                //         // Add new qualities to option
                //         defaultOptions.quality = {
                //             default: availableQualities[0],
                //             options: availableQualities,
                //             // this ensures Plyr to use Hls to update quality level
                //             forced: true,
                //             onChange: (et) => updateQuality(et),
                //         }

                //         // Initialize here
                //         var playesr = new Plyr(video__refrence, defaultOptions);
                //     });
                //     hls.attachMedia(video_tag);
                //     window.hls = hls;
                // } else {
                //     const player = new Plyr(video__refrence, defaultOptions);
                // }

                // function updateQuality(newQuality) {
                //     window.hls.levels.forEach((level, levelIndex) => {
                //         if (level.height === newQuality) {
                //             console.log("Found quality match with " + newQuality);
                //             window.hls.currentLevel = levelIndex;
                //         }
                //     });
                // }

                const video = document.querySelector("video");
                const source = url;


                const defaultOptions = {};

                if (Hls.isSupported()) {

                    const hls = new Hls();
                    hls.loadSource(source);

                    hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {

                        // Transform available levels into an array of integers (height values).
                        const availableQualities = hls.levels.map((l) => l.height)

                        // Add new qualities to option
                        defaultOptions.quality = {
                            default: availableQualities[0],
                            options: availableQualities,
                            // this ensures Plyr to use Hls to update quality level
                            forced: true,
                            onChange: (e) => updateQuality(e),
                        }

                        // Initialize here
                        const player = new Plyr(video, defaultOptions);
                    });
                    hls.attachMedia(video);
                    window.hls = hls;
                } else {
                    const player = new Plyr(video, defaultOptions);
                }

                function updateQuality(newQuality) {
                    window.hls.levels.forEach((level, levelIndex) => {
                        if (level.height === newQuality) {
                            console.log("Found quality match with " + newQuality);
                            window.hls.currentLevel = levelIndex;
                        }
                    });
                }


            } else if (extension === 'supermpd') {
                const defaultOptions = {};

                if (drmkey) {
                    var protData = {
                        "com.widevine.alpha": {
                            "serverURL": drmkey,
                            priority: 0
                        }
                    };


                }



                var video = document.querySelector("video");
                var playera = dashjs.MediaPlayer().create();
                // playera.setAutoSwitchQuality(false);   


                window.playera = playera;

                playera.initialize(video, url, true);

                if (drmkey) {
                    playera.setProtectionData(protData);
                }



                // Qualites Handler
                video.onloadeddata = (event) => {
                    var bitrates = playera.getBitrateInfoListFor("video");
                    const availableQualities = bitrates.map((l) => l.height);

                    console.log('Bitrates available:' + bitrates.length);
                    console.log(bitrates);

                    if (bitrates.length > 1) {
                        // Add new qualities to option
                        defaultOptions.quality = {
                            default: availableQualities[1],
                            options: availableQualities,
                            // this ensures Plyr to use Hls to update quality level
                            forced: true,
                            onChange: (e) => updateQuality(e),
                        }
                    }
                    const player1 = new Plyr(video__refrence, defaultOptions);
                };

                function updateQuality(newQuality) {
                    window.playera.updateSettings({ 'streaming': { 'abr': { 'autoSwitchBitrate': { 'video': false } } } });

                    window.playera.getBitrateInfoListFor("video").forEach((level, levelIndex) => {
                        if (level.height === newQuality) {
                            console.log("Found quality match with " + newQuality);
                            window.playera.setQualityFor("video", levelIndex);
                            // window.playera.setAutoSwitchQuality(false);

                        }
                    });
                }
                // Qualites Handler



                // window.playera.addEventListener("initialized", function(){
                //     var bitrates = playera.getBitrateInfoListFor("video");
                //     console.log('Bitrates available:' + bitrates.length);

                //     // bitrates are sorted from lowest to the best values

                //     // so the last one has the best quality
                //     // maxQuality = bitrates[bitrates.length-1].qualityIndex;
                //     // set max quality
                //     // player.setQualityFor("video", maxQuality);

                // });




                // console.log(playera);


            }

            else {
                if (!subtitle) {
                    try{
                    var node = document.createElement("source");
                    node.src = url;
                    document.querySelector("video").appendChild(node);

                    const player = new Plyr(video__refrence);
                    document.querySelector("video").play();

                    }catch(ex){
                        console.log(ex);
                    }
                }
            }





            //progress saving functionality
            // document.querySelector("video").play();

            const videolol = document.querySelector('video');


            if (subtitle) {
                axios.get(subtitle).then(res => {
                    var node = document.createElement("source");
                    node.src = url;
                    document.querySelector("video").appendChild(node);

                    var track = document.querySelector('video').addTextTrack('subtitles', 'English', 'en');
                    track.mode = "showing";
                    // console.log(track,"subtitle");

                    quick_and_dirty_vtt_or_srt_parser(res.data).map(function (cue) {
                        track.addCue(cue);
                    });

                    const player = new Plyr(video__refrence, { captions: { active: true } });
                    document.querySelector("video").play();


                })
                    .catch(e => console.log(e, "Subtitle Expections"));

            }


            var minutes = 0;
            var repppp = 0;

            // Assume "video" is the video node
            var i = setInterval(function () {

                try {
                    repppp++;
                    if (document.body.contains(document.querySelector('video'))) {
                        if (videolol.readyState > 0) {
                            minutes = parseInt(videolol.duration / 60, 10);
                            clearInterval(i);
                        }
                    }

                    if (repppp === 40) {
                        clearInterval(i);
                    }
                } catch (e) { console.log(e) };


            }, 200);

            if (document.body.contains(document.querySelector('video'))) {
                if (extension !== 'supermpd' && extension !== 'superm3u8') {

                    const oldDurr = window.localStorage.getItem(url);
                    if (oldDurr) {
                        videolol.currentTime = oldDurr;
                    }

                    videolol.ontimeupdate = (event) => {
                        console.log('The currentVideo Time =>', videolol.currentTime);
                        if (videolol.currentTime && videolol.currentTime !== 0 && minutes > 15) {
                            window.localStorage.setItem(url, videolol.currentTime);
                        }
                    };
                }
            }

            //progress saving functionality


        }


    }

    return (
        <div style={{ height: height ? height : '720px' }, { ...style }}>

            <div id="main__player__jw">
                {(extension !== 'mp4' && extension !== 'mpd' && extension !== 'm3u8' && extension !== 'youtube' && extension !== 'embed' && extension !== 'superm3u8' && extension !== 'supermpd') &&
                    (
                        <div className="video__tag">
                            <video autoPlay id="main_video" height={height ? height : '720px'} width="100%" data-poster={poster ? poster : LOGO_URL}>

                                <source src={url} type="video/mp4" />
                                {/* Captions are optional  */}
                                {/* {subtitle && <track id="subtitles" label="English" kind="subtitles" src={subtitle} srcLang="en" />} */}

                            </video>
                        </div>
                    )
                }

                {extension === 'youtube' && (
                    <div>
                        <div className="plyr__video-embed" id="main_video">
                            <iframe
                                src={`https://www.youtube.com/embed/${url}?origin=https://plyr.io&amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1`}
                                allowFullScreen
                                allowTransparency
                                allow="autoplay"
                            ></iframe>
                        </div>
                    </div>
                )}

                {extension === 'embed' && (
                    <div>
                        <iframe
                            src={`${url}`}
                            allowFullScreen
                            allowTransparency
                            allow="autoplay"
                            frameBorder="none"
                            width="100%"
                            height={height ? height : '720px'}
                        ></iframe>
                    </div>
                )}


                {extension === 'supermpd' && (
                    <div className="video__tag">
                        <video autoPlay id="main_video" height={height ? height : '720px'} width="100%" src={url} data-poster={poster ? poster : LOGO_URL}>

                            {/* Captions are optional  */}
                            {subtitle && <track id="subtitles" label="English" kind="subtitles" src={subtitle} srclang="en" />}

                        </video>
                    </div>
                )}


                {extension === 'superm3u8' && (
                    <div>
                        <div className="video__tag">
                            <video preload="auto" autoPlay id="main_video" height={height ? height : '720px'} width="100%" data-poster={poster ? poster : LOGO_URL} playsInline controls preload="auto" data-setup='{}'>
                                <source src={url} type="application/x-mpegURL" />

                                {/* Captions are optional  */}
                                {subtitle && <track id="subtitles" label="English" kind="subtitles" src={subtitle} srclang="en" />}

                            </video>
                        </div>
                    </div>
                )}


            </div>



        </div>
    )
}
