import "./poster.scss"
import { motion } from "framer-motion";
import { posterFadeInVariants } from "../../motionUtils";
import { FALLBACK_IMG_URL } from "../../requests";
import {  FaPlay, FaPlus } from "react-icons/fa";
// import { showModalDetail } from "../../redux/modal/modal.actions";
// import { useDispatch } from "react-redux";
// import { addToFavourites, removeFromFavourites } from "../../redux/favourites/favourites.actions";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";

import { useHistory } from "react-router";

const Poster = result => {
	const {  item : {name, poster:poster_path, thumb:backdrop_path ,id:dataID},genre,type:playType,isLarge} = result;

	let fallbackTitle = name;


   var history = useHistory();




	const handleModalOpening = () => {
		// Redirect();
        if(playType === 'channel'){
		history.push(`/play/${playType}/${dataID}`);
        }else{
            history.push(isMobile ? `/play/${playType}/${dataID}`: `/${playType}/${dataID}`);
        }
		// dispatch(showModalDetail({ ...item, fallbackTitle, genresConverted, isFavourite }));
	}
	const handlePlayAction = (e) => {
		console.log(e.target);
		e.stopPropagation();
	};

    return (
        <motion.div
            variants={posterFadeInVariants}
            className='Poster'
            onClick={handleModalOpening}
        >
            {backdrop_path ? (
                <img src={`${backdrop_path}`} alt={fallbackTitle} />
            ) : (
                <>
                    <img src={FALLBACK_IMG_URL} alt={fallbackTitle} />
                    <div className='Row__Poster__fallback'>
                        <span>{fallbackTitle}</span>
                    </div>
                </>
            )}
            <div className="Poster__info">
                <div className="Poster__info--iconswrp">
                    <Link
                        className="Poster__info--icon icon--play"
						onClick={() => handlePlayAction}
						to={`/play/${playType}/${dataID}`}
                    >
                        <FaPlay />
                    </Link>
     

                    <Link className='Poster__info--icon icon--favourite' to={`/${playType}/${dataID}`}>
                                <FaPlus />
                            </Link>

                    {/* <button className='Poster__info--icon icon--toggleModal'>
                        <FaChevronDown onClick={handleModalOpening}/>
                    </button> */}
                </div>
                <div className="Poster__info--title">
                    <h3>{fallbackTitle}</h3>
                </div>
                <div className="Poster__info--genres">
                {genre && (
						<span key={`Genre--id_${genre?.id}`} className="genre-title">{genre?.name}</span>
					)}
                </div>
            </div>
        </motion.div>
    )
}

export default Poster
