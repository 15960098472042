
export const GITHUB_BASE_URL = "https://github.com/dgbkn";
export const CREDITS_BASE_URL = "https://github.com/dgbkn";
export const GITHUB_AVATAR_URL = "https://www.kindpng.com/picc/m/78-786207_user-avatar-png-user-avatar-icon-png-transparent.png";
const GITHUB_ASSETS_BASE_URL = "https://cdn.jsdelivr.net/gh/dgbkn/flixyfroontend@main";
export const LANG = "en-US";
export const REGION = "US";
export const BASE_IMG_URL = "https://image.tmdb.org/t/p/original";
export const BASE_IMG_URL_SMALL = "https://image.tmdb.org/t/p/w500";
export const BASE_IMG_THUMB_URL = "https://image.tmdb.org/t/p/w200";
export const BASE_IMG_EXTRASMALLTHUMB_URL = "https://image.tmdb.org/t/p/w154";
export const BASE_IMG_SUPERSMALLTHUMB_URL = "https://image.tmdb.org/t/p/w192";

// movie/497698/credits

export const FALLBACK_IMG_URL = `${GITHUB_ASSETS_BASE_URL}/logo.png`;
export const LOGO_URL = `${GITHUB_ASSETS_BASE_URL}/logo.png`;
export const LOGO_GIF_URL = `${GITHUB_ASSETS_BASE_URL}/logo.gif`;
export const MOBILE_LOGO_URL = `${GITHUB_ASSETS_BASE_URL}/fav.png`;
export const PROFILE_PIC_URL = "https://www.kindpng.com/picc/m/78-786207_user-avatar-png-user-avatar-icon-png-transparent.png";
export const JWPLAYER_API_KEY  = "W7zSm81+mmIsg7F+fyHRKhF3ggLkTqtGMhvI92kbqf/ysE99";

export const TMDB_API_URL = 'https://api.themoviedb.org/3/';
// export const TMDB_URL_ADDER = "?api_key=11df31d6a6ca0ba9a5a48329717c59bc&language=en-US&region=US";
export const TMDB_URL_ADDER = "?api_key=11df31d6a6ca0ba9a5a48329717c59bc";
// export const SIGNIN_BGIMG_URL = `${GITHUB_ASSETS_BASE_URL}/logo.png`;
// export const SIGNIN_BGIMG_URL = `https://cdn.jsdelivr.net/gh/Th3Wall/assets-cdn/Fakeflix/Fakeflix_signin_bg.jpg`;
