import firebase from "firebase/app";
import 'firebase/messaging';

var firebaseConfig = {
  apiKey: "AIzaSyDdeDfTYAdXqCU-53lWQGMV0hYH0GKTmqo",
  authDomain: "newflixy.firebaseapp.com",
  projectId: "newflixy",
  storageBucket: "newflixy.appspot.com",
  messagingSenderId: "992803977280",
  appId: "1:992803977280:web:09609dc9117d7ef03fc7a5"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const messaging = firebase.messaging();



export const registerServiceWorker = () => {
  const fire_base_token_name = 'FiReBaSe__ToKeN';
  const oldToken = window.localStorage.getItem(fire_base_token_name) ? window.localStorage.getItem(fire_base_token_name) : false;

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
      .register('/sw.js')
      .then(function (registration) {



         if(!oldToken){
        //firebase messager
        messaging.getToken({vapidKey: 'BAE7gJ9gmRdLx_aZc7btyMhBiUYjOkfXyp2yejCinsWiN_d_bxfbs9_1GuUqlCa0B2X7Q5UPq0DiwVagVQULi0Y',serviceWorkerRegistration:registration}).then((currentToken) => {
          if (currentToken) {
            console.log('current token for client: ', currentToken);
            window.localStorage.setItem(fire_base_token_name, currentToken);
            // Track the token -> client mapping, by sending to backend server
            // show on the UI that permission is secured
          } else {
            console.log('No registration token available. Request permission to generate one.');
            window.localStorage.setItem(fire_base_token_name, false);
            // shows on the UI that permission is required 
          }
        }).catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
          window.localStorage.setItem(fire_base_token_name, false);
          // catch error while creating client token
        });
        //firebase messager
      }

          



          // eslint-disable-next-line no-console
          console.log('[SW]: SCOPE: ', registration.scope);
          return registration.scope;
        })
        .catch(function (err) {
            console.log(err);
          return err;
        });

        navigator.serviceWorker
        .ready
        .then(function(ev) {
            console.log('Service Worker Ready',ev);
        })
        .catch(function (err) {
            console.log(err);
          return err;
        });
    }
  };