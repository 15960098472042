// import { useEffect } from "react"
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Navbar from "./components/Navbar/Navbar";
import Homepage from "./pages/Homepage/Homepage";
import MoviePlayer from "./pages/PlayerPages/MoviePlayer";
import ChannelPlayer from "./pages/PlayerPages/ChannelPlayer";
import MovieDetails from "./pages/Details/MovieDetails";
import ChannelDetails from "./pages/Details/ChannelDetails";
import SerieDetails from "./pages/Details/SerieDetails";
import SeriePlayer from "./pages/PlayerPages/SeriePlayer";
import CastDetails from './pages/Casts/Details';
import SearchPage from "./pages/SearchPage/SearchPage";
import FallBack from "./pages/Fallback";
import BasePage from "./pages/SingleCategory/BasePage";
// import Navbar from "./components/Navbar/Navbar"
// import Homepage from "./pages/Homepage/Homepage"
// import Movies from "./pages/Movies/Movies"
// import TVSeries from './pages/TVSeries/TVSeries';
// import Popular from "./pages/Popular/Popular";
// import Search from "./pages/Search/Search";
// import Category from "./pages/Category/Category";
// import DetailModal from "./components/DetailModal/DetailModal";

function App() {
  const location = useLocation();


  return (
    <div className="App">
      <Navbar />
      <AnimatePresence exitBeforeEnter>
        <Switch location={location} key={location.pathname}>


          <Route exact path="/">
            <Homepage />
          </Route>

          <Route exact path="/play/movie/:id">
            <MoviePlayer />
          </Route>

          <Route exact path="/play/channel/:id">
            <ChannelPlayer />
          </Route>

          <Route exact path="/play/serie/:id">
            <SeriePlayer />
          </Route>

          <Route exact path="/movie/:id">
            <MovieDetails />
          </Route>


          <Route exact path="/channel/:id">
            <ChannelDetails />
          </Route>

          <Route exact path="/serie/:id">
            <SerieDetails />
          </Route>


          <Route exact path="/series">
            <BasePage type={`series`} />
          </Route>

          <Route exact path="/movies">
            <BasePage type={`movies`} />
          </Route>

          <Route exact path="/channels">
            <BasePage type={`channels`} />
          </Route>

          <Route exact path="/castdetail/:id">
            <CastDetails />
          </Route>

          <Route exact path="/search/:query">
            <SearchPage />
          </Route>

          <Route exact path="/fallback">
            <FallBack type={'201'} />
          </Route>

          <Route path="">
            <FallBack type={'404'} />
          </Route>

          <Route path="*">
            <FallBack type={'404'} />
          </Route>


        </Switch>
      </AnimatePresence>
    </div>

  );
}

export default App;
