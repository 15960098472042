import "./searchbar.scss"
import { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
import { RiCloseFill } from "react-icons/ri";
import useOutsideClick from "../../hooks/useOutsideClick";
import { motion } from "framer-motion";


const Searchbar = ({setTopValue}) => {

    const history = useHistory();
    const [searchInputToggle, setSearchInputToggle] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const searchbarRef = useRef();
    const searchInputRef = useRef();

    useOutsideClick(searchbarRef, () => {
        if (searchInputToggle) {
            setSearchInput("");
            setSearchInputToggle(false);
            setTopValue('10px');

        }
    });

    const handleSearchInputToggle = () => {
        searchInputRef.current.focus();
        setSearchInputToggle(!searchInputToggle);
        if(searchInputToggle === true){
            setTopValue('10px');
        }

        if(searchInputToggle === false){
            setTopValue('70px');
        }
    };

    const clearSearchInputToggle = () => {
        setSearchInput("");
        history.push("/");
    };

    const handleSearchInput = event => {
        const { value } = event.target;
        setSearchInput(value);

        if (value.length > 0) {
            history.push(`/search/${encodeURIComponent(value)}`);
        } else history.push("/");
    };

    return (
        <motion.div
        initial={{ x: -100 }}
        animate={{ x:0 }}

        className="Searchbar" ref={searchbarRef}>
            <input
                type="text"
                placeholder="Search movies, series, channels"
                value={searchInput}
                onChange={handleSearchInput}
                ref={searchInputRef}
                className={`Searchbar--search ${searchInputToggle ? "Searchbar--active" : ""}`}
            />
            <div
                className="Searchbar--toggler"
                onClick={handleSearchInputToggle}
            >
                <FiSearch size="1.5em" />
            </div>
            <div
                className={`Searchbar--clear ${searchInputToggle && searchInput.length ? "typing" : ""}`}
                onClick={clearSearchInputToggle}
            >
                <RiCloseFill />
            </div>
        </motion.div>
    )
}

export default Searchbar
