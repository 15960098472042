import "./banner.scss";
import React from "react";
import { motion } from "framer-motion";
import { staggerOne, bannerFadeInLoadSectionVariants, bannerFadeInVariants, bannerFadeInUpVariants } from "../../motionUtils";
import { FaPlay } from "react-icons/fa";
import { BiInfoCircle } from "react-icons/bi";
import { randomize, truncate } from "../../utils";
import { Link } from "react-router-dom";
import SkeletonBanner from "../SkeletonBanner/SkeletonBanner";
import useFetch from "../../hooks/useFetch";

// import { useDispatch, useSelector } from "react-redux";
// import { showModalDetail } from "../../redux/modal/modal.actions";
// import { selectTrendingMovies, selectNetflixMovies } from "../../redux/movies/movies.selectors";
// import { selectNetflixSeries } from "../../redux/series/series.selectors";


//this component Will Randomly Load The Things

const Banner = ({ type }) => {

let playType;
    playType = type.substring(0, type.length - 1);
    var { loading, error, data: results } = useFetch(type);

	// switch (type) {
	// 	case "movies":
	//        playType = type.substring(0, type.length - 1);
	// 		  break;
	// 	case "series":
	// 		  playType = type.substring(0, type.length - 1);
	// 		  break;
	// 	case "channels":
	// 		  playType = type.substring(0, type.length - 1);
	// 		  break;		
	// 	default:
	// 		  playType = 'movie';
	// 		  break;
	// }


	results = results ? results['content'] : false;
	const finalData = results ? results[randomize(results)] : false;
	console.log(finalData)

	const fallbackTitle = finalData?.name || finalData?.title;
	const dataID = finalData?.id;
	const description = truncate(finalData?.description, 150);


	const handlePlayAnimation = event => {
		event.stopPropagation();
	};

	const handleModalOpening = () => {
		// dispatch(showModalDetail({ ...finalData, fallbackTitle }));
	}

	return (
		<>
			<motion.section
				variants={bannerFadeInLoadSectionVariants}
				initial='initial'
				animate='animate'
				exit='exit'
				className="Banner__loadsection"
			>
				{loading && <SkeletonBanner />}
				{error && <div className="errored">Oops, an error occurred.</div>}
			</motion.section>

			{!loading && finalData && (
				<motion.header
					variants={bannerFadeInVariants}
					initial='initial'
					animate='animate'
					exit='exit'
					className="Banner"
					style={{backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) ),url('${finalData?.poster}')`}}
				>
					<motion.div
						className="Banner__content"
						variants={staggerOne}
						initial='initial'
						animate='animate'
						exit='exit'
					>
						<motion.h1 variants={bannerFadeInUpVariants} className="Banner__content--title">{fallbackTitle}</motion.h1>
						<motion.div variants={bannerFadeInUpVariants} className="Banner__buttons">
							<Link
								className="Banner__button"
								onClick={handlePlayAnimation}
								to={`/play/${playType}/${dataID}`}
							>
								<FaPlay />
								<span>Play</span>
							</Link>

							<Link
								className="Banner__button"
								onClick={handleModalOpening}
								to={`/${playType}/${dataID}`}

							>
								<BiInfoCircle size="1.5em" />
								<span>More info</span>
							</Link>
						</motion.div>
						<motion.p variants={bannerFadeInUpVariants} className="Banner__content--description">{description}</motion.p>
					</motion.div>
					<div className="Banner__panel" />
					<div className="Banner__bottom-shadow" />
				</motion.header>
			)}
		</>
	)
}

export default React.memo(Banner);