import React from 'react'
import { motion } from "framer-motion";
import { staggerOne, bannerFadeInLoadSectionVariants, bannerFadeInVariants, bannerFadeInUpVariants, defaultPageFadeInVariants, defaultEasing } from "../../motionUtils";
import Credits from "../../components/Credits/Credits";
import useFetch from '../../hooks/useFetch';

import { useParams } from 'react-router-dom';
import SkeletonBasicPage from '../../components/SkeletonBasicPage/SkeletonBasicPage';
import { useEffect } from 'react';
import '../../components/Banner/banner.scss';
import useViewport from '../../hooks/useViewport';
import { FaPlay } from "react-icons/fa";
import { Link } from 'react-router-dom';

import './VideoBackground.scss';
import SkeletonElement from '../../components/SkeletonElement/SkeletonElement';
import RowCasts from '../../components/Row/RowCasts';
import useFetchtmdb from '../../hooks/useFetchtmdb';
import { randomize, truncate } from "../../utils";
import { BASE_IMG_URL,BASE_IMG_URL_SMALL } from '../../requests';

export default function MovieDetails() {
    var { id } = useParams();
    var { width, height } = useViewport();

    var { data, error, loading } = useFetch('movie/' + id);

    var { data: casts, error: errcasts, loading: loadingcasts } = useFetchtmdb(`movie/${data?.tmdb}/credits`);

    var { data: images, error: errimages, loading: loadingimages } = useFetchtmdb(`movie/${data?.tmdb}/images`,"&include_image_language=en,hi");


    //     const  handleHoverMain = e => {
    //         try{
    //         if(data?.trailer){
    //      e.target.style.background = `none`;
    //      e.target.style.backgroundImage = `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) )`;
    //      if(document.getElementById('yt__Trailer').src !== `https://www.youtube.com/embed/${data?.trailer}?autoplay=1&mute=1&controls=0&showinfo=0&rel=0&loop=1&playlist=${data?.trailer}`){
    //      document.getElementById('yt__Trailer').src = `https://www.youtube.com/embed/${data?.trailer}?autoplay=1&mute=1&controls=0&showinfo=0&rel=0&loop=1&playlist=${data?.trailer}`;

    //      }
    //     }
    // } catch(e){
    //     console.log(e);
    // }
    //     }

    useEffect(() => {
        try {
            setTimeout(() => {
                window.scrollTo(0, 0);

            }, [1000])
        } catch (e) {
            console.log(e);
        }

        return () => { console.log('removed Movie Details') }
    }, []);


    // var [currentSource, setCurrentSource] = useState(null);


    // handleChangeSrc = (e) => {
    //     changeSrc(e.target.value);
    // }



    return (
        <div>
            {!loading && !error && <motion.div
                className="Homepage"
                variants={defaultPageFadeInVariants}
                initial="initial"
                animate="animate"
                exit="exit"
            >




                <br />


                {data?.trailer &&





                    (

                        <div className="video-background" >
                            <div className="video-foreground">
                                <iframe id="yt__Trailer" src={`https://www.youtube.com/embed/${data?.trailer}?autoplay=1&mute=1&controls=0&showinfo=0&rel=0&loop=1&playlist=${data?.trailer}`} frameBorder="0" allowFullScreen></iframe>
                            </div>
                        </div>

                    )

                }

                {/* <JWPlayer
    height={width >= 780 ? '900px' : '400px'}
    url={data?.trailer}
    extension={'youtube'}
    subtitle={data?.subtitle}
    poster={data?.poster}
    style={{position:'relative',zIndex: 9,
    width: '100%',
    top: '-100px'}}
/> */}


                <motion.div className="vid-info"
                    whileHover={{ scale: 1.2 }}
                    initial={{ opacity: 1 }}
                    animate={{ opacity: 0 }}
                    transition={{ type: "tween", duration: 4 }}
                >
                    <img src={`${data?.thumb}`} style={{ height: '40%', width: '40%' }} />
                </motion.div>

                <motion.section
                    variants={bannerFadeInLoadSectionVariants}
                    initial='initial'
                    animate='animate'
                    exit='exit'
                    className="Banner__loadsection"
                    id="vidtop-content"
                >
                    <motion.div
                        variants={bannerFadeInVariants}
                        initial={{ opacity: 0, transition: { duration: 1, ease: defaultEasing }, willChange: "opacity, transform", backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ) ,url('${data?.poster}')` }}
                        animate={{ opacity: 1, transition: { duration: 1, ease: defaultEasing }, willChange: "opacity, transform", backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) )`, background: `none` }}
                        exit={{ opacity: 0, transition: { delay: .4, duration: 1, ease: defaultEasing }, willChange: "opacity, transform" }}
                        className="Banner"
                        // whileHover={{ backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) )`,background:`none` }}
                        // onHoverStart={handleHoverMain}
                        // onHoverEnd={handleHoverMain}
                        // onFocus={handleHoverMain}
                        id='Main_trailer_loader'
                        // style={}
                        style={{ backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ) ` }}

                    >
                        <motion.div
                            className="Banner__content"
                            style={{ zIndex: 10 }}
                            variants={staggerOne}
                            initial='initial'
                            animate='animate'
                            exit='exit'
                        >


                            <br />

                            {images?.logos && !errimages && images?.logos[0]?.file_path && (
                                <motion.img variants={bannerFadeInUpVariants} src={`${BASE_IMG_URL_SMALL}${images?.logos[randomize(images?.logos)].file_path}`} width="35%" />  
                            )}


                            {loadingimages || !images?.logos || !images?.logos[0]?.file_path && (<motion.h1 variants={bannerFadeInUpVariants} className="Banner__content--title"> {data?.name}</motion.h1>
                            )}

                            <motion.div variants={bannerFadeInUpVariants} className="Banner__buttons">

                                <Link
                                    className="Banner__button"
                                    to={`/play/movie/${id}`}
                                >
                                    <FaPlay />
                                    <span>Play</span>
                                </Link>


                                <a
                                    className="Banner__button"
                                    href={`https://www.youtube.com/watch/?v=${data?.trailer}`}
                                    target="_blank"
                                >
                                    <FaPlay />
                                    <span>Play Trailer</span>
                                </a>

                            </motion.div>
                            <motion.h1 variants={bannerFadeInUpVariants} className="Banner__content--description">Votes : {data?.vote}</motion.h1>
                            <motion.h1 variants={bannerFadeInUpVariants} className="Banner__content--description">Popularity : {data?.popularity}</motion.h1>

                            <motion.p variants={bannerFadeInUpVariants} className="Banner__content--description">{data?.description}</motion.p>
                        </motion.div>



                        <div className="Banner__panel" />
                        <div className="Banner__bottom-shadow" />
                    </motion.div>


                </motion.section>
            </motion.div >

            }

            <motion.h1 variants={bannerFadeInUpVariants} style={{ marginLeft: '50px' }} className="Banner__content--description">Casts: </motion.h1>

            {
                casts && !errcasts && (
                    <RowCasts casts={casts} />
                )
            }




            {loadingcasts && <SkeletonElement />}

            {loading && <SkeletonBasicPage />}

            <Credits />
        </div >
    )
}
